import styled from 'styled-components'
import { sh } from 'config/variables'
export const MobileNewBenefitWorkStyle = styled.section`
  margin: 0;
  @media (max-width: 1024px) {
    height: auto !important;
    .heaing-wrapper {
      h3 {
        text-align: center;
      }
    }
  }
`
export const NewBenefitWorkStyle = styled.section`
  position: sticky;
  overflow: hidden;
  top: 0;
  background: linear-gradient(to top, #eef2ff, #ffffff);
  height: 100vh;
  padding: 97px 0;
  .oveflowHoriaontal{
    overflow:hidden;
  }
  .oveflowHoriaontalInner{
    overflow-x:scroll;
    margin-bottom: -20px;
  }
  .new-benefits-listing {
    box-shadow: 0 5px 10px 0 rgba(161, 175, 221, 0.09);
    display: flex;
    will-change: transform;
    transform-style: preserve-3d;
    margin-left: calc((100vw - 1140px) / 2);
    .single-block {
      min-width: 650px;
      max-width: 650px;
      padding: 43px 60px 300px;
      position: relative;
      border: 1px solid #e8e9f3;
      border-right: 0;
      background: rgba(255, 255, 255, 0.5);
      &:last-child {
        border-right: 1px solid #e8e9f3;
      }
      ${sh(`
        padding: 33px 50px 280px;
      `)}
      h4 {
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -0.33px;
        color: #222;
        margin-bottom: 10px;
        font-weight: 500;
      }
      p {
        font-size: 20px;
        letter-spacing: -0.15px;
        line-height: 28px;
        margin: 0;
      }
      ul {
        padding-left: 30px;
        li {
          font-size: 20px;
          line-height: 30px;
          letter-spacing: -0.15px;
        }
      }
      .img-block {
        position: absolute;
        right: 40px;
        bottom: 30px;
        ${sh(`
          bottom: 10px;
        `)}
      }
    }
  }
  .new-benefits-listing-mobile {
    display: none;
  }
  @media (max-width: 1024px) {
    height: 100%;
    position: relative;
    padding: 50px 0;
    .new-benefits-listing {
      display: none;
    }
    .new-benefits-listing-mobile {
      overflow-x: auto;
      display: flex;
      .single-block {
        min-width: 650px;
        max-width: 650px;
        padding: 43px 60px 170px;
        position: relative;
        border-right: 1px solid #e8e9f3;
        &:last-child {
          border-right: 0;
        }
        h4 {
          font-size: 30px;
          line-height: 36px;
          letter-spacing: -0.33px;
          color: #222;
          margin-bottom: 10px;
          font-weight: 500;
        }
        p {
          font-size: 20px;
          letter-spacing: -0.15px;
          line-height: 28px;
          margin: 0;
        }
        ul {
          padding-left: 30px;
          li {
            font-size: 20px;
            line-height: 30px;
            letter-spacing: -0.15px;
          }
        }
        .img-block {
          position: absolute;
          right: 40px;
          bottom: 30px;
        }
      }
    }
  @media (max-width: 767px) {
    position: relative;
    padding: 20px 0 70px;
    .new-benefits-listing-mobile {
      border: 1px solid #e8e9f3;
      box-shadow: 0 5px 10px 0 rgba(161, 175, 221, 0.09);
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      flex-direction: column;
      .single-block {
        width: 100%;
        min-width:100%;
        max-width: 650px;
        padding: 36px 20px 0px;
        position: relative;
        border-bottom: 1px solid #e8e9f3;
        &:last-child {
          border-bottom: 0;
        }
        h4 {
          font-size: 28px;
          line-height: 34px;
          letter-spacing: -0.33px;
          color: #222;
          margin-bottom: 10px;
          font-weight: 500;
        }
        p {
          font-size: 18px;
          letter-spacing: -0.15px;
          line-height: 28px;
          margin: 0;
        }
        ul {
          padding-left: 30px;
          li {
            font-size: 20px;
            line-height: 30px;
            letter-spacing: -0.15px;
          }
        }
        .img-block {
          right: 0;
          bottom: 0;
          position:relative;
          padding: 40px 0 36px;
          text-align: center;
        }
      }
    }
  }
`
