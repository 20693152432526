import React, { useEffect, useRef } from 'react'
import {
  NewBenefitWorkStyle,
  MobileNewBenefitWorkStyle,
} from './NewBenefitWork.styles'
import { isElementInViewport } from 'config/utils'

const ITEM_WIDTH = 650
// let currentItem = 1
// let totalItems = 5
// let itemWidth = 390
// let totalWidth = -(totalItems * itemWidth)
// let trackLength = 300

// const updateTranslateValue = ({ up = true } = {}) => {
// if (up) {
//   if (currentItem === totalItems * 2 + 1) {
//     return false
//   }
//   currentItem += 1
//   return -(currentItem * (itemWidth / 2))
// } else {
//   if (currentItem === 0) {
//     return false
//   }
//   currentItem -= 1
//   return -(currentItem * (itemWidth / 2))
// }
// let current = window.pageYOffset
// let total = window.innerHeight - current

// let newPosition = trackLength * (current / total)

// return newPosition
// }

const NewBenefitWork = ({ newbenefitWorkdata: { newBenefitWorkProps } }) => {
  let [
    itemsContainerRef,
    mainHeightRef,
    startPoint,
    oldValue,
    newValue,
    childrenLen,
    position,
  ] = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()]
  let mainDiv = 'scroll-container-horizontal'
  let translateContainer = 'new-benefits-listing'
  let horizontalDiv = 'oveflowHoriaontalInner'

  position.current = 0
  oldValue.current = 0

  useEffect(() => {
    // let horizontal = document.querySelector(`.${translateContainer}`)
    let itemsContainer = itemsContainerRef.current

    // let d = 0
    if (itemsContainer) {
      childrenLen.current = Array.from(itemsContainer.children).length
      let width = childrenLen.current * ITEM_WIDTH
      itemsContainer.style.width = `${width + 200}px`
      mainHeightRef.current.style.height = `${width + 600}px`
      mainHeightRef.current.classList.add('static-class')
      // d = width
    }
    let mainDivSelector = document.querySelector(`.${mainDiv}`)
    let horizonralSelector = document.querySelector(`.${horizontalDiv}`)
    let mainOffsetTop = mainDivSelector.offsetTop - 50
    let mouseWheelUpOrDown = 0

    document.addEventListener('wheel', e => {
      if (e.deltaY < 0) {
        if (
          window.pageYOffset >=
          mainOffsetTop + mainDivSelector.offsetHeight - 751
        ) {
          mouseWheelUpOrDown = 1
        } else {
          mouseWheelUpOrDown = 0
        }
      } else if (e.deltaY > 0) {
        if (window.pageYOffset <= mainOffsetTop) {
          mouseWheelUpOrDown = 0
        } else {
          mouseWheelUpOrDown = 1
        }
      }
    })
    document.addEventListener('scroll', e => {
      let isInViewport = isElementInViewport(mainDivSelector, mainOffsetTop)
      if (isInViewport) {
        let scrollDisplacement = window.pageYOffset - mainOffsetTop
        let scrollWidth = mainOffsetTop + mainDivSelector.offsetHeight - 751

        let scrollGreyPart = parseInt(
          window.innerHeight * (window.innerHeight / document.body.offsetHeight)
        )
        let totalScroll = parseInt(
          (100 * scrollDisplacement) / (scrollWidth - scrollGreyPart)
        )

        let horizontalScrollWidth = horizonralSelector.scrollWidth
        let horizontalScrollBar = horizonralSelector.offsetWidth
        let horizontalWidth = horizontalScrollWidth - horizontalScrollBar

        let horizontalScroll = parseInt((totalScroll * horizontalWidth) / 100)

        if (horizonralSelector.scrollLeft !== horizontalScroll) {
          requestAnimationFrame(() => {
            horizonralSelector.scroll
              ? horizonralSelector.scroll({
                  left: horizontalScroll,
                  behavior: 'instant',
                })
              : (horizonralSelector.scrollLeft = horizontalScroll)
          })
        }
      } else {
        if (mouseWheelUpOrDown) {
          if (
            horizonralSelector.scrollLeft !== horizonralSelector.scrollWidth
          ) {
            requestAnimationFrame(() => {
              horizonralSelector.scroll
                ? horizonralSelector.scroll({
                    left: horizonralSelector.scrollWidth,
                    behavior: 'instant',
                  })
                : (horizonralSelector.scrollLeft =
                    horizonralSelector.scrollWidth)
            })
          }
        } else {
          if (horizonralSelector.scrollLeft !== 0) {
            requestAnimationFrame(() => {
              horizonralSelector.scroll
                ? horizonralSelector.scroll({
                    left: 0,
                    behavior: 'instant',
                  })
                : (horizonralSelector.scrollLeft = 0)
            })
          }
        }
      }
    })
  }, [
    itemsContainerRef,
    mainHeightRef,
    mainDiv,
    translateContainer,
    startPoint,
    oldValue,
    newValue,
    childrenLen,
    position,
    horizontalDiv,
  ])

  return (
    <MobileNewBenefitWorkStyle ref={mainHeightRef}>
      <NewBenefitWorkStyle className={mainDiv}>
        <div className="container">
          <div className="heaing-wrapper">
            <h3>{newBenefitWorkProps.heading}</h3>
          </div>
        </div>
        <div className="oveflowHoriaontal">
          <div className="oveflowHoriaontalInner">
            <div className={translateContainer} ref={itemsContainerRef}>
              {newBenefitWorkProps.benefitListProps.map(
                (benefitListProp, i) => {
                  return (
                    <div key={i} className="single-block">
                      {benefitListProp.title ? (
                        <h4>{benefitListProp.title}</h4>
                      ) : (
                        ''
                      )}
                      {benefitListProp.para ? (
                        <p>{benefitListProp.para}</p>
                      ) : (
                        ''
                      )}
                      {benefitListProp.lists ? (
                        <ul>
                          {benefitListProp.lists.map((list, i) => {
                            return <li key={`${i}${i}`}>{list}</li>
                          })}
                        </ul>
                      ) : (
                        ''
                      )}
                      <div className="img-block">
                        <img
                          src={benefitListProp.img}
                          alt={benefitListProp.imgAlt}
                        />
                      </div>
                    </div>
                  )
                }
              )}
            </div>
          </div>
        </div>
        <div className="new-benefits-listing-mobile">
          {newBenefitWorkProps.benefitListProps.map((benefitListProp, i) => {
            return (
              <div key={i} className="single-block">
                {benefitListProp.title ? <h4>{benefitListProp.title}</h4> : ''}
                {benefitListProp.para ? <p>{benefitListProp.para}</p> : ''}
                {benefitListProp.lists ? (
                  <ul>
                    {benefitListProp.lists.map((list, i) => {
                      return <li key={`${i}${i}`}>{list}</li>
                    })}
                  </ul>
                ) : (
                  ''
                )}
                <div className="img-block">
                  <img src={benefitListProp.img} alt={benefitListProp.imgAlt} />
                </div>
              </div>
            )
          })}
        </div>
      </NewBenefitWorkStyle>
    </MobileNewBenefitWorkStyle>
  )
}

export default NewBenefitWork
