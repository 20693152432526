import React from 'react'
import { images } from 'config/images'

export const DedicatedDevelopmentProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Dedicated software development <br />
        team <span className="heading-red">to help your business win</span>
      </>
    ),
    bannerContent: (
      <>
        Rev up your software development with our team of top-notch tech wizards
        who deliver seamless solutions that meet all your project needs - on
        time, on budget, and beyond your expectations!
      </>
    ),
    BannerAlt: 'Dedicated software development team',
    buttonName: 'Hire Top Talent',
    buttonLink: '/contact/',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>Kickstart Your Software Development Project with Our Dedicated Teams!</>
    ),
    buildAppPara: (
      <>
        Ready to take your software development to the next level? Our dedicated
        development team empowers you to focus on the big picture by driving
        daily tasks and ensuring code quality, while you scale your development
        capacity without the hassle of hiring or onboarding, with up to 60-70%
        reduction in team costs.
        <br />
        <br />
        As a trusted tech partner with 13 years of experience, Simform assembles
        custom-tailored teams that efficiently address your skill gaps and
        ensure seamless collaboration, delivering your projects on time and on
        budget.
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    videoNote: 'Play video to see how we work',
  },
  serviceBenefitsProps: {
    workingBenefitsHeading: (
      <>
        <span className="highlight-head">Benefits</span> of hiring
        <br /> through Simform
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle:
          'Easy team extension to remove complexity from hiring',
        workingBenefitsContent: (
          <>
            While finding developers and freelancers is easy -- the difficulty
            comes in vetting them and making sure you get the person you need.
            It takes 800 hours to <strong>hire a team</strong> of 5 good people,
            onboard them, and have them start working on the project. And at the
            end of this ordeal, you're not even sure if you made the right
            choice or not.
          </>
        ),
        benefitImgAlt: 'Hire a team',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle:
          'Effortlessly Manage Your Dedicated Development Team for Optimal Results!',
        workingBenefitsContent: (
          <>
            On top of that, you’re busy. You’ve got products to ship. Deadlines
            to hit. CTOs, Directors, and PMs can't be expected to shift their
            focus to hiring when their time can be spent better elsewhere. Our{' '}
            <strong>Dedicated Development Team model</strong> is designed for
            companies who want to quickly ramp up their development capacity or
            extend their internal dev teams without any hassle.
          </>
        ),
        benefitImgAlt: 'Dedicated development team model',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Talent pool at scale',
        workingBenefitsContent: (
          <>
            <strong>Quickly scale up</strong> and down, with no need to get into
            a long, burdensome hiring process. With hundreds of developers
            across a wide range of skillsets, Simform gives you the flexibility
            of extending your team with{' '}
            <strong>offshore dedicated development teams.</strong>
          </>
        ),
        benefitImgAlt: 'Scale with Simform',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Managed engagement',
        workingBenefitsContent: (
          <>
            Most of our competitors provide TRANSACTIONAL hiring services. You
            post a gig, they match you up with a developer, and you take it from
            there. We help you build software without having to{' '}
            <strong>manage employees</strong> by using proven processes. By
            partnering with Simform, one of the leading{' '}
            <strong>outsourcing vendors</strong>, you gain access to{' '}
            <strong>remote developers</strong> who are managed by our
            experienced team, ensuring smooth collaboration and efficient
            project delivery.
          </>
        ),
        benefitImgAlt: 'Managed employees',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Reduce overheads',
        workingBenefitsContent: (
          <>
            <strong>Cut down recruiting costs</strong> and operational overhead
            as there are no infrastructure costs or costs associated with hiring
            full-time employees. Most importantly with the proven team, there
            are no time-to-market revenue losses.
          </>
        ),
        benefitImgAlt: 'Reduced overheads',
      },
    ],
  },
  CTACard: {
    title: <>Build your dream project with top 2% of tech talent</>,
    buttonName: 'Schedule a call',
    buttonLink: '/contact/',
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        How Simform can Help You Hire the{' '}
        <span className="highlight-head">Best Dedicated Development Teams</span>
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'pale-lilac-three',
        workingBenefitsTitle: 'Contact us about your project',
        workingBenefitsContent: (
          <>
            Let us know about your <strong>project needs and goals</strong>—the
            more details the better. <br /> <br /> Our tech team then connects
            with you over a series of meetings in order to understand your
            business goals, project, tech stack, and all the other nuances that
            define your technical requirements.{' '}
          </>
        ),
        benefitImgAlt: 'Project goals',
        workingBenefitsPatern: images.processflow1,
      },
      {
        workingBenefitsClass: 'pale-mauve-three',
        workingBenefitsTitle: 'Tech roadmap with a hiring plan',
        workingBenefitsContent: (
          <>
            Depending on your goals, we prepare a <strong>tech roadmap</strong>{' '}
            with a <strong>hiring plan</strong> that includes details on what
            skill set and experience your team needs to have. <br /> <br /> The
            detailed tech roadmap includes things like what tech stack to use
            and how to navigate through tech complexities of the project.{' '}
          </>
        ),
        benefitImgAlt: 'Tech roadmap',
        workingBenefitsPatern: images.processflow2,
      },
      {
        workingBenefitsClass: 'light-sky-blue-two',
        workingBenefitsTitle: 'Sourcing talent to match your needs',
        workingBenefitsContent: (
          <>
            We look internally to find the <strong>perfect candidates</strong>{' '}
            for you first. If we don't find the right candidate then we tap into
            our talent network to see if we can hire through our partners.{' '}
            <br /> <br /> If not then we hunt, <strong>run campaigns</strong>,
            use our recruiting strength to hire candidates matching your specs.
          </>
        ),
        benefitImgAlt: 'Candidates for dedicated team',
        workingBenefitsPatern: images.processflow3,
      },
      {
        workingBenefitsClass: 'pale-four',
        workingBenefitsTitle: 'Our screening process',
        workingBenefitsContent: (
          <>
            When we have candidates we like we give them a stage to showcase
            their communication skills and{' '}
            <strong>problem-solving ability</strong> through a series of video
            interviews. <br /> <br /> Here, we are looking for people with
            highly relevant skill sets, <strong>well-documented code</strong>,
            and a disciplined approach to testing.{' '}
          </>
        ),
        benefitImgAlt: 'Screening process',
        workingBenefitsPatern: images.processflow4,
      },
      {
        workingBenefitsClass: 'light-periwinkle-two',
        workingBenefitsTitle: 'Test projects to measure skills',
        workingBenefitsContent: (
          <>
            Comprehensive small project tests are given to each developer. Their
            technical acumen is tested through an{' '}
            <strong>automated coding exam.</strong> <br /> <br /> Focus is given
            to <strong>language-specific knowledge</strong> as well as general
            programming and algorithm knowledge.
          </>
        ),
        benefitImgAlt: 'Testing projects',
        workingBenefitsPatern: images.processflow5,
      },
      {
        workingBenefitsClass: 'pale-sky-blue',
        workingBenefitsTitle: 'Selection and hiring',
        workingBenefitsContent: (
          <>
            We do full reference checks on your behalf with their previous
            clients and employers. We sign NDA and{' '}
            <strong>full proof legal contract</strong> to make sure your IP is
            protected. <br /> <br /> Speed is what Simform takes pride in — it
            typically takes only <strong>24-72 hours</strong> for our clients to
            receive a short list of top candidates.
          </>
        ),
        benefitImgAlt: 'Selection and hiring',
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Our recent <span className="highlight-head">success stories</span>
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  techstacksProps: {
    heading: 'Technology expertise',
    tabContents: [
      {
        tabName: 'Mobile',
        tabimages: [
          {
            src: images.iconReactNative,
            alt: 'ReactNative',
          },
          {
            src: images.iosSwift,
            alt: 'iosSwift',
          },
          {
            src: images.kotlinlangLogo,
            alt: 'kotlinlang',
          },
          {
            src: images.bitriseioLogo,
            alt: 'bitriseio',
          },
          {
            src: images.iconNative,
            alt: 'Native',
          },
        ],
      },
      {
        tabName: 'Web',
        tabimages: [
          {
            src: images.iconElectron,
            alt: 'Electron',
          },
          {
            src: images.socketioLogo,
            alt: 'socketio',
          },
          {
            src: images.knockoutLogo,
            alt: 'socketio',
          },
          {
            src: images.iconRubyOnRails,
            alt: 'Ruby On Rails',
          },
        ],
      },
      {
        tabName: 'Frontend development',
        tabimages: [
          {
            src: images.reduxSagaLogo,
            alt: 'reduxSaga',
          },
          {
            src: images.storyBookLogo,
            alt: 'storyBook',
          },
          {
            src: images.nextjsLogo,
            alt: 'nextjs',
          },
          {
            src: images.graphqlLogo,
            alt: 'graphql',
          },
          {
            src: images.RxSwiftLogo,
            alt: 'RxSwift',
          },
          {
            src: images.iconReact,
            alt: 'React',
          },
          {
            src: images.iconAngularJS,
            alt: 'AngularJS',
          },
          {
            src: images.iconVue,
            alt: 'Vue',
          },
        ],
      },
      {
        tabName: 'Backend',
        tabimages: [
          {
            src: images.iconPHP,
            alt: 'PHP',
          },
          {
            src: images.iconNodeJS,
            alt: 'Node.js',
          },
          {
            src: images.iconAspNET,
            alt: 'asp.Net (C#)',
          },

          {
            src: images.iconPython,
            alt: 'Python',
          },
        ],
      },
      {
        tabName: 'Headless CMS/e-commerce',
        tabimages: [
          {
            src: images.headlessLogo,
            alt: 'headless cms',
          },
          {
            src: images.iconShopify,
            alt: 'Shopify',
          },
          {
            src: images.iconStripe,
            alt: 'Stripe',
          },
          {
            src: images.iconMagento,
            alt: 'Magento',
          },
          {
            src: images.mauticLogo,
            alt: 'mautic',
          },
        ],
      },
      {
        tabName: 'Infrastructure',
        tabimages: [
          {
            src: images.elasticCacheLogo,
            alt: 'elasticCache',
          },
          {
            src: images.iconFirebase,
            alt: 'Firebase',
          },
          {
            src: images.terraformioLogo,
            alt: 'terraformio',
          },
          {
            src: images.iconJenkins,
            alt: 'Jenkins',
          },
          {
            src: images.iconRedis,
            alt: 'Redis',
          },
        ],
      },
      {
        tabName: 'Futuristic',
        tabimages: [
          {
            src: images.awsLogo,
            alt: 'aws',
          },
          {
            src: images.iconAzure,
            alt: 'Azure',
          },
          {
            src: images.iconGooglecloud,
            alt: 'Google cloud',
          },
          {
            src: images.devopsLogo,
            alt: 'devops',
          },
        ],
      },
      {
        tabName: 'Trending',
        tabimages: [
          {
            src: images.artificialIntelligenceLogo,
            alt: 'ai',
          },
          {
            src: images.blockchainLogo,
            alt: 'blockchain',
          },
          {
            src: images.iconSalesforce,
            alt: 'Salesforce',
          },
          {
            src: images.mlLogo,
            alt: 'ml',
          },
        ],
      },
      {
        tabName: 'Data Analytics',
        tabimages: [
          {
            src: images.mixpanelLogo,
            alt: 'mixpanel',
          },
          {
            src: images.powerbiLogo,
            alt: 'powerbi',
          },
          {
            src: images.tableauLogo,
            alt: 'tableau',
          },
        ],
      },
      {
        tabName: 'Database',
        tabimages: [
          {
            src: images.iconSQLServer,
            alt: 'SQLServer',
          },
          {
            src: images.iconPostageSQL,
            alt: 'PostageSQL',
          },
          {
            src: images.iconMySQL,
            alt: 'MySQL',
          },
        ],
      },
    ],
  },

  AboutprojectformProps: {
    title: (
      <>
        Tell Us About <span className="highlight-head">Your Project</span>
      </>
    ),
    formTitleL: 'Request a Free Quote',
    formInfo: 'Guaranteed response within one business day.',
    formDetailstitle: 'What will happen next?',
    formButtonText: 'LET"S GET STARTED',
    list: [
      'We’ll reach out to you within 24 hours.',
      'We’ll discuss your project and gather your requirements and business objectives, and develop a proposal accordingly.',
      'You can start 15-days risk-free trial with us.',
    ],
  },
  gauranteeSecProps: {
    heading: (
      <>
        Simform <span className="highlight-head">Guarantee</span>
      </>
    ),
    para: (
      <>
        We know that if a client’s project launches smoothly, they’ll come back
        for more. We're{' '}
        <span className="color-through">willing to over-invest</span> in
        guaranteeing results, rather than under-invest to make our financial
        reports look pretty in the short-run.
        <br />
        <br />
        We offer a <strong>risk-free trial period</strong> of up to two weeks.
        You will only have to pay if you are happy with the developer and wish
        to continue. If you are unsatisfied,{' '}
        <span className="color-through">we’ll refund payment</span> or fix
        issues on our dime.
      </>
    ),
    buttonName: 'Start Risk Free Trial',
    buttonURL: '/contact/',
    img: images.gauranteeTeam,
    img2x: images.gauranteeTeam2x,
    gauranteeAlt: 'Extended tech team',
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/top-outsourcing-lessons-learned-from-5-successful-and-failed-startups/',
        featurecardTitle:
          '9 Outsourcing Lessons Learned from Successful and Failed startups',
      },
      {
        blogLink: 'https://www.simform.com/how-to-develop-ecommerce-apps/',
        featurecardTitle: 'How to Make an eCommerce App',
      },
      {
        blogLink:
          'https://www.simform.com/healthcare-app-development-features-types/',
        featurecardTitle:
          'Healthcare App Development: Top Trends, Features & Types ',
      },
    ],
  },

  faqSectionProps: {
    heading: 'FAQs',
    faqProps: [
      {
        faqTitle:
          'When is the ‘dedicated software development team’ a good choice for software development?',
        faqPara: (
          <>
            There are many benefits to hiring a dedicated development team.
            Transparency of the process, sharing the business responsibilities,
            access to control the progress of work, and building the team
            quickly are the main benefits.
            <br /> <br />
            Here are some of the reasons when hiring a dedicated development
            team is a good choice:
            <br /> <br />
            <ul>
              <li>
                To save cost as compared to the increase in the inhouse staff
              </li>
              <li>
                shortage of talent or lack of particular technical skillset in
                your area
              </li>
              <li>
                When you want to put minimal efforts from your end to set up a
                new team
              </li>
              <li>The seasonal or cyclical workload with strict deadlines</li>
              <li>
                If your in-house team lacks in technical expertise required for
                software development.
              </li>
              <li>
                The minimization of risks with competent project management and
                trusted operating processes
              </li>
            </ul>
          </>
        ),
      },

      {
        faqTitle: 'How much time is required to assemble the right team?',
        faqPara: (
          <>
            It depends on the complexity of the project. Some clients entrust
            the selection of team members to respective project managers and
            incline with their choices. Whereas, others make it essential to be
            a part of all the scheduled interviews and personally give a
            thorough review of their resume. It takes a maximum of 3 days for
            our clients to receive a list of developers. On average, the entire
            process takes 7–10 days to build an expert team.
            <br /> <br />
            On the same hand, what makes the experts stand out in the crowd is
            “approach”. Like if you need a team of 15 people, firstly it will be
            recommended to hire 4–5 developers and then gradually increasing the
            team size. This method is a tried-tested approach to hire the best
            candidates to cement your footprint with your project.
          </>
        ),
      },

      {
        faqTitle: 'What is your dedicated developer vetting process?',
        faqPara: (
          <>
            Screening talent with resumes and interviews is outdated. When you
            hire through us you don't only hire from limited visibility and
            information from resumes, personal interviews, etc.
            <br /> <br />
            We have a comprehensive vetting process amongst our competitors.
            Developers are tested on their technical skills, communication
            skills, and project management skills.
          </>
        ),
      },

      {
        faqTitle:
          'How do you scale-up and scale-down the size of the dedicated development team?',
        faqPara: (
          <>
            Next comes the execution part to ramp-up and ramp-down teams, where
            a dedicated software development team can help greatly.
            <br /> <br />
            Unlike their clients, large software firms can allocate software
            engineers from closing projects to new openings fast. Even so, it is
            rarely an option to downsize many people from a dedicated software
            team at once. If you have a large team assigned to your projects,
            down-scaling can take a couple of months. Hence, you should plan
            your projects well in advance and share the plan with the project
            manager and business analysis team.
            <br /> <br />
            The clean delivery of a project also has to do with the relation
            between team members. An external firm that relies on an established
            dedicated team working process ensures the tasks are assigned
            correctly. And also keeps the morale is high. They usually assign to
            your project team members that have worked with each other, thus
            lowering the risk of workplace disputes.
            <br /> <br />
            Also, the small cultural differences among team members improve
            engagement and add to job happiness. Last, most providers enjoy
            higher retention rates than software product firms due to multiple
            factors. Of these, the growth contexts and team building events are
            among the most appealing to software engineers.
          </>
        ),
      },
      {
        faqTitle: 'How do you make working remotely successful?',
        faqPara: (
          <>
            Managing a remote team is not easy. There are critical mistakes you
            can make when trying to leverage the vast amounts of remote talent.
            <br /> <br />
            Simform team integrates into your team, participating in standup and
            scrum meetings, weekly demos, weekly retrospectives.
            <br /> <br />
            <strong>Daily stand-ups</strong> - We do daily stand-ups where
            everyone gets on a video chat and tells you what they are working on
            that day and the previous day. <br />
            <strong>Weekly demonstrations</strong> - This one is simple: get
            everybody on a video chat, share screens, have people show their
            work, and then talk about it. <br />
            <strong>Weekly Retrospectives</strong> - A weekly retrospective is
            where you all collectively review what went well and what could have
            been improved based on the demo.
          </>
        ),
      },

      {
        faqTitle: 'What is the cost of hiring a dedicated development team?',
        faqPara: (
          <>
            The cost of hiring a dedicated development team varies based on your
            project requirements, required skillset, and developer experience
            level. However, hiring a dedicated development team from Simform
            offers cost-effective advantages. By partnering with us, you
            eliminate the expenses associated with recruiting, onboarding, and
            infrastructure costs. We provide a transparent pricing model
            tailored to your project requirements, ensuring you get top talent
            without the burden of additional overheads.
          </>
        ),
      },
      {
        faqTitle:
          'Why should we hire a dedicated team of developers from Simform?',
        faqPara: (
          <>
            Hiring a dedicated team of developers from Simform brings many
            significant benefits. We offer a pre-vetted talent pool of proven
            experts, saving you the time and effort of searching for qualified
            professionals. Additionally, our managed engagement approach means
            you can focus on your core business while we handle the team
            management. What sets us apart is our strong engineering expertise
            that enables us to deliver premium software that entices users and
            benefits businesses.
          </>
        ),
      },
      {
        faqTitle: 'What is a dedicated team structure?',
        faqPara: (
          <>
            A dedicated team structure refers to a collaborative setup where you
            have a dedicated group of developers exclusively working on your
            project. At Simform, we assemble a dedicated development team
            tailored to your specific requirements, including the necessary
            skill sets and experience. This team becomes an extension of your
            internal development resources, working closely with you to achieve
            your business goals. With clear communication channels and a shared
            understanding of your project, the dedicated team structure ensures
            efficiency, focus, and seamless integration with your organization.
          </>
        ),
      },
    ],
    contactHeading: 'Have more questions?',
    contactPara: 'Let us know and our experts will get in touch with you ASAP.',
    supportImg: images.contactLadySm,
    buttonName: 'Talk to our experts',
    buttonLink: '/contact/',
  },
}
