import React, { memo, useEffect } from 'react'
import Layout from 'components/Layout/Layout'
import Banner from 'components/Banner/Banner'
import { DedicatedDevelopmentProps } from '../../components/Props/dedicated-development-team'
import styled from 'styled-components'
import SEO from 'components/Seo'
import BuildApplication from 'components/BuildApplication/BuildApplication'
import CaseStudySlider from 'components/CaseStudySlider/CaseStudySlider'
import { graphql, navigate } from 'gatsby'
import { images } from 'config/images'
import NewBenefitWork from 'components/NewBenefitWork/NewBenefitWork'
import ProcessFlow from 'components/ProcessFlow/ProcessFlow'
import GuaranteeSection from 'components/GuaranteeSection/GuaranteeSection'
import FaqSection from 'components/FaqSection/FaqSection'
import FeatureResources from 'components/FeatureResources/FeatureResources'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { fetchItem, getViewerCountry } from '../../config/utils'
import ContactInfoCard from 'components/ContactInfoCard/ContactInfoCard'
import Aboutprojectform from 'components/Aboutprojectform/Aboutprojectform'
import Techstacks from 'components/Techstacks/Techstacks'

export const DedicatedDevelopmentPage = styled.div`
  .banner {
    .banner-content {
      max-width: 830px;
      p {
        max-width: 780px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
    .banner-img {
      display: block;
    }
  }
  .feature-resource-section {
    background: linear-gradient(to top, #eef2ff, #ffffff);
    .contact-info-card {
      display: none;
    }
  }
  .ppcbenefit-section {
    .heading-wrapper {
      max-width: 740px;
    }
  }
`

const DDT = memo(props => {
  const { data } = props
  const { location } = props
  // const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent: true })

  let processFlowItem = [
    props.data.process1.childImageSharp.fluid,
    props.data.process2.childImageSharp.fluid,
    props.data.process3.childImageSharp.fluid,
    props.data.process4.childImageSharp.fluid,
    props.data.process5.childImageSharp.fluid,
    props.data.process6.childImageSharp.fluid,
  ]

  let serviceBenefitsItem = [
    props.data.serviceBenefits1.childImageSharp.fluid,
    props.data.serviceBenefits2.childImageSharp.fluid,
    props.data.serviceBenefits3.childImageSharp.fluid,
    props.data.serviceBenefits4.childImageSharp.fluid,
    props.data.serviceBenefits5.childImageSharp.fluid,
  ]
  let posterImage = [props.data.posterThumbImage.childImageSharp.fluid]
  return (
    <Layout
      mainClass="dedicated-dev-team services-page"
      location={location}
      data={{
        img: images.contactHiren,
        img2x: images.contactHiren_2x,
        name: 'Hiren Dhaduk',
        description:
          'Creating a tech product roadmap and building scalable apps for your organization.',
      }}
    >
      <SEO
        title="Dedicated Software Development Team"
        description="Our dedicated software development team will reimagine your business into a successful tech business by building innovative applications"
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/hire/dedicated-development-team/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/hire/dedicated-development-team/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <DedicatedDevelopmentPage>
        <Banner
          bannerData={DedicatedDevelopmentProps}
          showBannerImage={true}
          {...props}
        />
        <div className="lazy-load-div">
          <BuildApplication
            buildApplicationData={DedicatedDevelopmentProps}
            posterImage={posterImage}
          />
          <ProcessFlow
            ProcessFlowData={DedicatedDevelopmentProps.serviceBenefitsProps}
            items={serviceBenefitsItem}
          />
        </div>
        <ContactInfoCard {...DedicatedDevelopmentProps.CTACard} />
        <CaseStudySlider caseStudydata={DedicatedDevelopmentProps} {...props} />
        <Techstacks techstacksdata={DedicatedDevelopmentProps} />
        <ProcessFlow
          ProcessFlowData={DedicatedDevelopmentProps.workingBenefitsProps}
          items={processFlowItem}
        />
        <Aboutprojectform
          AboutprojectformData={DedicatedDevelopmentProps.AboutprojectformProps}
        />
        <GuaranteeSection
          gauranteesecdata={DedicatedDevelopmentProps}
          {...props}
        />
        <FaqSection faqSectiondata={DedicatedDevelopmentProps} />
        <FeatureResources
          featureresourceData={DedicatedDevelopmentProps}
          {...props}
        />
      </DedicatedDevelopmentPage>
    </Layout>
  )
})

export default DDT

export const query = graphql`
  query {
    bannerImage: file(
      relativePath: { regex: "/dedicated-dev-banner@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1231) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case1: file(relativePath: { regex: "/case-onlymob@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case2: file(relativePath: { regex: "/case-safari@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case3: file(relativePath: { regex: "/case-ipad@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case4: file(relativePath: { regex: "/case-mobile@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case5: file(relativePath: { regex: "/redbull-case@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    gauranteeTeam: file(relativePath: { regex: "/gaurantee-team@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1238) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication1: file(
      relativePath: { regex: "/blog-outsourcing-lessons@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication2: file(
      relativePath: { regex: "/blog-ecommerce-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication3: file(
      relativePath: { regex: "/blog-healthcare-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    servicebenefit: file(
      relativePath: { regex: "/dedicated-benefit-banner@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 744) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process1: file(relativePath: { regex: "/project-about-process@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 310) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process2: file(relativePath: { regex: "/hiring-plan-process@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 340) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process3: file(
      relativePath: { regex: "/sourcing-talent-process@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 328) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process4: file(relativePath: { regex: "/screening-process@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 340) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process5: file(relativePath: { regex: "/measure-skills-process@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 327) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process6: file(
      relativePath: { regex: "/selection-hiring-process@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 338) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process7: file(relativePath: { regex: "/manual-qa@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 408) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process8: file(relativePath: { regex: "/security-testing@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 408) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    serviceBenefits1: file(relativePath: { regex: "/ill-hiring@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    serviceBenefits2: file(relativePath: { regex: "/ill-teammodal@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    serviceBenefits3: file(
      relativePath: { regex: "/ill-quicklyscale@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    serviceBenefits4: file(relativePath: { regex: "/ill-manageemp@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    serviceBenefits5: file(relativePath: { regex: "/ill-overheads@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    posterThumbImage: file(relativePath: { regex: "/poster-img.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
